.infoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settingOption {
    font-size: 16px;
}

.hellow {
    // color: white;
    margin-right: 24px;
}

.settings {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    cursor: pointer;
}
