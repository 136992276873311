.wrapper {
    cursor: pointer;
    background-color: #e5b7f1;
    display: inline-block;
    border-radius: 12px;
    padding: 5px 10px;
    color: #555555;
    margin-bottom: 5px;
    position: relative;
    & + & {
        margin-left: 16px;
    }
}

.badge {
    position: absolute;
    top: -15px;
    right: -12px;
    
    border-radius: 100%;
    z-index: 1;
    padding: 0 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 100%;
    height: auto;
}