.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 auto;
}

.loginFormWrapper {
    margin-bottom: 2rem;
}

.content {
    width: 100%;
    max-width: 350px;
    text-align: center;
    margin-bottom: 40px;
}

.title {
    margin-bottom: 1.2rem;
    font-size: 22px;
}

.googleSignWrapper {
    margin-bottom: 1.2rem;
}

.staff {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5;
}
