.formWrapper {
    position: relative;
}

.searchResultWrapper {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    top: 100%;
    left: 0;
    background-color: white;
    width: 700px;
    z-index: 10;
    padding: 8px;
    border-radius: 2px 2px 8px 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.itemCard {
    width: 18%;
    cursor: pointer;
    & + & {
        margin-left: 1%;
    }
}

.paginationWrapper {
    display: flex;
    justify-content: right;
    width: 100%;
}
