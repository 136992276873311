.userWrapper {
    display: flex;
    flex-wrap: wrap;
}

.textarea {
    width: 100%;
    height: 150px;
    border-radius: 16px;
    resize: none;
    padding: 2%;
    border: 1px solid #d9d9d9;
    &:focus {
        outline: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
}

.button {
    width: 100%;
    height: 40px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
}

.userSettingWrapper {
    margin-bottom: 16px;
    width: 95%;
}
