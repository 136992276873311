.wrapper {
    tbody {
        tr:nth-child(odd) {
            background-color: #f3f4f8;
            td {
                background-color: #f3f4f8;
            }
        }
        tr:nth-child(even) {
            background-color: white;
            td {
                background-color: white;
            }
        }
    }
}
