.header {
    margin-bottom: 10px;
    width: 100%;
}

.title {
    font-size: 22px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 8px;
}

.desc {
    color: #9696a0;
    margin-bottom: 25px;
    font-size: 14px;
}

.guide_wrap {
    padding-bottom: 8px;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    justify-content: space-between;
}

.guide_wrap h1 {
    font-size: 22px;
}
