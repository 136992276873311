.section {
    margin-bottom: 20px;
}

.userBadgeWrapper {
    margin: 15px 0;
}

.footer {
    text-align: right;
    button {
        margin-left: 32px;
        width: 100px;
    }
}

.permissionHeader {
    display: flex;
    align-items: center;
    h1 {
        font-size: 18px;
        font-weight: 500;
    }
    .input {
        margin-left: 16px;
        width: 250px;
        height: 32px;
    }
}
