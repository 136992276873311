.inputWrapper {
    display: flex;
}

.itemInputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 24px;
}

.wrapper {
    .itemWrapper {
        margin-top: 16px;
        padding: 20px;
        width: 95%;
        box-shadow: 8px 8px 7px #d9d9d9;
        h4 {
            margin-bottom: 0;
        }
    }
}

.itemName {
    font-size: 14px;
}
