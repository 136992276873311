.main {
    min-height: 100vh;
}

.adminMain {
    width: 100%;
    min-height: 100vh;
    padding: 135px 60px 60px;
}

.mainWrapper {
    display: flex;
}
.contentMain {
    width: 100%;
    min-height: 100vh;
    padding: 135px 60px 60px;
}

.project-lang-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.project-lang-item {
    cursor: pointer;
}
