.formWrapper {
    padding-right: 50px;
}

.footerWrapper {
    text-align: right;
}

.button {
    margin-left: 16px;
}