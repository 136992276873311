.titleWrapper {
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        font-size: 24px;
        padding-bottom: 12px;
    }
    margin-bottom: 10px;
}
.section {
    margin-bottom: 32px;
}
.wrapper {
    h1 {
        font-size: 24px;
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: 12px;
        padding-bottom: 12px;
    }
}
