.footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.dateString {
    font-size: 14px;
}

.buttonWrapper {
    width: 100%;
}

.button {
    width: 100px;
}

.userBadgeWrapper {
    margin: 15px 0 0;
}
