.KO {
    font-family: 'Noto Sans KR', sans-serif !important;
}

.JA {
    font-family: 'Noto Sans JP' !important;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
img,
strong,
ul,
li,
form,
label,
footer,
header,
menu,
section,
button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

li {
    list-style: none;
}

.ck.ck-content ul,
.ck.ck-content ol {
    padding-left: 40px;
}

.ck.ck-content ul li {
    list-style: disc;
}

.ck.ck-content ol li {
    list-style: decimal;
}

/* antd css */
.ant-layout {
    background-color: #f6faff;
}

.ant-layout.prod {
    background-color: #fff9f9;
}

.ant-layout.qa {
    background-color: #f6faff;
}

.ant-layout.dev {
    background-color: #f6f6f6;
}

.ant-modal {
    top: 30px;
}

.ant-form-item-no-colon {
    font-weight: 500;
}

.ant-form-item {
    margin-bottom: 10px;
}

.ant-table-content {
    box-shadow: 0px 0px 15px #ebebeb;
}

.ant-table-cell {
    font-size: 13px;
}

.ant-table-thead .ant-table-cell {
    font-size: 14px;
}

.ant-dropdown.gamelist .ant-dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
}

/* antd header dropdown menu - HeaderMenu.tsx*/
.ant-dropdown-menu-item-group {
    border-top: 1px solid #eee;
    margin-top: 10px;
}
.ant-dropdown-menu-item-group:first-child {
    border-top: 0;
    margin-top: 0;
}
.ant-dropdown-menu-item-group-title {
    color: #000000;
}
